/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { memo, useState } from 'react';
import Alerts from './Alerts';
import Destinations from './Destinations';
import { prettyCount } from '../helper';
import {
  AlarmIcon,
  PeopleIcon,
  Tab,
  TabPanel,
  Tabs as MuiTabs,
} from '../material';
import { criticalText, msTeams, spacingLg } from '../style';
import type { Alert, Destination } from '../api';

interface TabsProps {
  readonly alerts: readonly Alert[] | undefined;
  readonly destinations: readonly Destination[] | undefined;
  readonly editAlert: (alert?: Alert) => void;
  readonly editDestination: (destination?: Destination) => void;
}

const Tabs = ({
  alerts,
  destinations,
  editAlert,
  editDestination,
}: TabsProps) => {
  const [value, setValue] = useState(0);

  return (
    <>
      <MuiTabs
        value={value}
        setValue={setValue}
        css={css`
          margin-top: ${spacingLg};
          margin-bottom: ${spacingLg};
        `}
      >
        <Tab
          index={0}
          icon={<PeopleIcon />}
          label={
            <span
              css={css`
                color: ${msTeams};
              `}
            >
              {prettyCount('Teams Channel', destinations)}
            </span>
          }
        />
        <Tab
          index={1}
          icon={<AlarmIcon />}
          label={
            <span
              css={css`
                color: ${criticalText};
              `}
            >
              {prettyCount('Alert', alerts)}
            </span>
          }
        />
      </MuiTabs>
      <TabPanel index={0} value={value}>
        <Destinations
          alerts={alerts}
          destinations={destinations}
          editDestination={editDestination}
        />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <Alerts
          alerts={alerts}
          destinations={destinations}
          editAlert={editAlert}
        />
      </TabPanel>
    </>
  );
};

export default memo(Tabs);
