/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { AppBar as MuiAppBar } from '@mui/material';
import { brandBlue } from '../style';
import type { AppBarProps } from '@mui/material';

export const AppBar = (props: AppBarProps) => (
  <MuiAppBar
    css={css`
      background: ${brandBlue};
    `}
    {...props}
  />
);
