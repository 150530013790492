import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import type { ApolloError } from '@apollo/client';
import { GET_DESTINATIONS } from './useDestinations';
import type { Mutation, MutationDeleteDestinationArgs } from '..';

const DELETE_DESTINATION = gql`
  mutation deleteDestination($id: ID!) {
    deleteDestination(id: $id)
  }
`;

export type DeleteDestination = (id: string) => Promise<string>;

export const useDeleteDestination = (): readonly [
  DeleteDestination,
  boolean,
  ApolloError | undefined
] => {
  const [mutate, { loading, error }] = useMutation<
    Mutation,
    MutationDeleteDestinationArgs
  >(DELETE_DESTINATION, {
    refetchQueries: [{ query: GET_DESTINATIONS }, 'getDestinations'],
  });

  const deleteDestination = useCallback(
    (id: string) =>
      mutate({ variables: { id } }).then(
        ({ data }) => data?.deleteDestination || id
      ),
    [mutate]
  );

  return [deleteDestination, loading, error];
};
