import { memo } from 'react';
import { languageLabel } from '../label';
import { Link } from '../../material';

import type { DeepPartial } from 'ts-essentials';
import type { AlertInput } from '../../api';

interface Props {
  readonly alert: DeepPartial<AlertInput>;
}

const MessageHelperText = ({ alert: { language } }: Props) => (
  <>
    <div>
      Some{' '}
      <Link
        href='https://learn.microsoft.com/en-us/adaptive-cards/authoring-cards/text-features'
        target='_blank'
        rel='noreferrer'
      >
        Markdown
      </Link>{' '}
      is supported.
    </div>
    <div>
      <code>{'{waiting_time}'}</code> gets replaced by a summary of the waiting
      time
      {language && <>, in {languageLabel[language]}</>}.
    </div>
  </>
);

export default memo(MessageHelperText);
