import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { GET_ALERTS } from './useAlerts';
import type { ApolloError } from '@apollo/client';
import type { Mutation, MutationDeleteAlertArgs } from '..';

const DELETE_ALERT = gql`
  mutation deleteAlert($id: ID!) {
    deleteAlert(id: $id)
  }
`;

export type DeleteAlert = (id: string) => Promise<string>;

export const useDeleteAlert = (): readonly [
  DeleteAlert,
  boolean,
  ApolloError | undefined
] => {
  const [mutate, { loading, error }] = useMutation<
    Mutation,
    MutationDeleteAlertArgs
  >(DELETE_ALERT, { refetchQueries: [{ query: GET_ALERTS }, 'getAlerts'] });

  const deleteAlert = useCallback(
    (id: string) =>
      mutate({ variables: { id } }).then(({ data }) => data?.deleteAlert || id),
    [mutate]
  );

  return [deleteAlert, loading, error];
};
