import { memo } from 'react';
import AlertForm from './AlertForm';
import { Dialog } from '../../material';
import type { Alert, Destination } from '../../api';

interface Props {
  readonly close: () => void;
  readonly destinations: readonly Destination[] | undefined;
  readonly open: boolean;
  readonly saved: Alert | undefined;
}

const AlertDialog = ({ close, destinations, open, saved }: Props) => (
  <Dialog open={open} onClose={close}>
    {open && (
      <AlertForm destinations={destinations} onClose={close} saved={saved} />
    )}
  </Dialog>
);

export default memo(AlertDialog);
