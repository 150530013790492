/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { memo } from 'react';
import {
  AppBar,
  Container,
  HelpIcon,
  IconButton,
  Toolbar,
  Typography,
} from '../material';
import { spacingLg } from '../style';

const Top = () => (
  <AppBar position='static'>
    <Container maxWidth='xl'>
      <Toolbar disableGutters>
        <img
          src='/image/kry-logo-neg.svg'
          alt='Kry'
          height={38}
          width={74}
          css={css`
            padding-top: 6px;
            margin-right: ${spacingLg};
          `}
        />
        <Typography
          variant='h1'
          noWrap
          css={css`
            cursor: default;
          `}
        >
          Clinician Alerts
        </Typography>
        <IconButton
          color='inherit'
          href='https://www.notion.so/kry-livi/Instructions-to-setup-the-waiting-time-alerts-for-MS-Teams-cc6a97b66d1a47d7b1f8258a0b3efe47'
          target='_blank'
          rel='noreferrer'
          title='Help'
          css={css`
            margin-left: auto;
          `}
        >
          <HelpIcon />
        </IconButton>
      </Toolbar>
    </Container>
  </AppBar>
);

export default memo(Top);
