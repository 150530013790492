import { ApolloProvider } from '@apollo/client';
import { memo } from 'react';
import Layout from './Layout';
import { client } from '../apollo';
import { ThemeProvider } from '../material';
import { theme } from '../style';

const App = () => (
  <ThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <Layout />
    </ApolloProvider>
  </ThemeProvider>
);

export default memo(App);
