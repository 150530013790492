export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  LocalDateTime: string;
  LocalTime: string;
};

export type Alert = {
  readonly __typename?: 'Alert';
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly country: Country;
  readonly destinationId: Scalars['ID'];
  readonly end: AlertPhase;
  readonly id: Scalars['ID'];
  readonly intervention: Intervention;
  readonly language: Language;
  readonly profession: Profession;
  readonly reminder: Reminder;
  readonly start: AlertPhase;
  readonly throttleMinutes: Scalars['Int'];
};

export type AlertInput = {
  readonly country: Country;
  readonly destinationId: Scalars['ID'];
  readonly end: AlertPhaseInput;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly intervention: Intervention;
  readonly language: Language;
  readonly profession: Profession;
  readonly reminder: ReminderInput;
  readonly start: AlertPhaseInput;
  readonly throttleMinutes: Scalars['Int'];
};

export type AlertPhase = {
  readonly __typename?: 'AlertPhase';
  readonly lastDateTime?: Maybe<Scalars['LocalDateTime']>;
  readonly maxTime?: Maybe<Scalars['LocalTime']>;
  readonly maxWaitingTimeMinutes?: Maybe<Scalars['Int']>;
  readonly messageTemplate?: Maybe<Scalars['String']>;
  readonly minTime?: Maybe<Scalars['LocalTime']>;
  readonly minWaitingTimeMinutes?: Maybe<Scalars['Int']>;
};

export type AlertPhaseInput = {
  readonly maxTime?: InputMaybe<Scalars['LocalTime']>;
  readonly maxWaitingTimeMinutes?: InputMaybe<Scalars['Int']>;
  readonly messageTemplate?: InputMaybe<Scalars['String']>;
  readonly minTime?: InputMaybe<Scalars['LocalTime']>;
  readonly minWaitingTimeMinutes?: InputMaybe<Scalars['Int']>;
};

export enum Country {
  Fr = 'FR',
  Gb = 'GB',
  No = 'NO',
  Se = 'SE',
}

export type Destination = {
  readonly __typename?: 'Destination';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type DestinationInput = {
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly teamsWebhookUrl: Scalars['String'];
};

export enum Intervention {
  ChatMeeting = 'CHAT_MEETING',
  VideoMeeting = 'VIDEO_MEETING',
}

export enum Language {
  En = 'en',
  Fr = 'fr',
  Nb = 'nb',
  Sv = 'sv',
}

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly deleteAlert: Scalars['ID'];
  readonly deleteDestination: Scalars['ID'];
  readonly saveAlert: Alert;
  readonly saveDestination: Destination;
};

export type MutationDeleteAlertArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteDestinationArgs = {
  id: Scalars['ID'];
};

export type MutationSaveAlertArgs = {
  alert: AlertInput;
};

export type MutationSaveDestinationArgs = {
  destination: DestinationInput;
};

export enum Profession {
  Doctor = 'doctor',
  Nurse = 'nurse',
}

export type Query = {
  readonly __typename?: 'Query';
  readonly getAlerts: ReadonlyArray<Alert>;
  readonly getDestinations: ReadonlyArray<Destination>;
};

export type Reminder = {
  readonly __typename?: 'Reminder';
  readonly lastDateTime?: Maybe<Scalars['LocalDateTime']>;
  readonly messageTemplate?: Maybe<Scalars['String']>;
  readonly throttleMinutes: Scalars['Int'];
};

export type ReminderInput = {
  readonly messageTemplate?: InputMaybe<Scalars['String']>;
  readonly throttleMinutes: Scalars['Int'];
};
