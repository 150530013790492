import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import type { ApolloError } from '@apollo/client';
import { GET_DESTINATIONS } from './useDestinations';
import type {
  Destination,
  DestinationInput,
  Mutation,
  MutationSaveDestinationArgs,
} from '..';

const SAVE_DESTINATION = gql`
  mutation saveDestination($destination: DestinationInput!) {
    saveDestination(destination: $destination) {
      id
      name
    }
  }
`;

export type SaveDestination = (
  destination: DestinationInput
) => Promise<Destination | undefined>;

export const useSaveDestination = (): readonly [
  SaveDestination,
  boolean,
  ApolloError | undefined
] => {
  const [mutate, { loading, error }] = useMutation<
    Mutation,
    MutationSaveDestinationArgs
  >(SAVE_DESTINATION, {
    refetchQueries: [{ query: GET_DESTINATIONS }, 'getDestinations'],
  });

  const saveDestination = useCallback(
    (destination: DestinationInput) =>
      mutate({
        variables: { destination: stripExtraFields(destination) },
      }).then(({ data }) => data?.saveDestination),
    [mutate]
  );

  return [saveDestination, loading, error];
};

const stripExtraFields = ({
  id,
  name,
  teamsWebhookUrl,
}: DestinationInput): DestinationInput => ({
  id,
  name,
  teamsWebhookUrl,
});
