import { useLocalStorage } from 'react-use';
import type { Dispatch, SetStateAction } from 'react';

export const getApiKey = (): string | null => {
  const apiKey = localStorage.getItem('apiKey');

  return apiKey && JSON.parse(apiKey);
};

export const useApiKey = (): [
  string,
  Dispatch<SetStateAction<string | undefined>>,
  () => void
] => {
  const [apiKey, setApiKey, remove] = useLocalStorage<string>('apiKey');

  return [apiKey || '', setApiKey, remove];
};
