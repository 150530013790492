import { memo } from 'react';
import { prettyCount } from '../helper';

interface Props {
  readonly minutes: number | null | undefined;
}

const Minutes = ({ minutes }: Props) =>
  typeof minutes === 'number' ? (
    <span title={prettyCount('minute', minutes)}>
      {prettyCount('min', minutes)}
    </span>
  ) : null;

export default memo(Minutes);
