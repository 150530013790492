/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { TableCell as MuiTableCell } from '@mui/material';
import type { TableCellProps as MuiTableCellProps } from '@mui/material';

export interface TableCellProps extends MuiTableCellProps {
  /**
   * `true` if this `TableCell` only contains one icon.
   */
  icon?: boolean;
}

export const TableCell = (props: TableCellProps) => (
  <MuiTableCell
    {...props}
    css={[
      css`
        line-height: 1.5;

        .MuiTableHead-root & {
          font-size: 12px;
          font-weight: bold;
        }
      `,
      props.icon &&
        css`
          padding-top: 0;
          padding-bottom: 0;
          width: 0;

          svg {
            display: block;
          }
        `,
    ]}
  />
);
