/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Formik, Form } from 'formik';
import { memo } from 'react';
import DestinationDelete from './DestinationDelete';
import { useSaveDestination } from '../../api';
import { teamsWebhookPattern } from '../../helper';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
} from '../../material';
import type { Destination, DestinationInput } from '../../api';

interface Props {
  readonly onClose: () => void;
  readonly saved: Destination | undefined;
}

const DestinationForm = ({ onClose, saved }: Props) => {
  const [saveDestination, saving] = useSaveDestination();

  const onSubmit = (destination: Partial<DestinationInput>) => {
    saveDestination(destination as DestinationInput).then(onClose);
  };

  return (
    <Formik initialValues={saved || {}} onSubmit={onSubmit}>
      {({ setFieldValue, values: destination }) => (
        <Form>
          <DialogTitle>
            {destination.id ? 'Edit' : 'Add'} Microsoft Teams Channel
          </DialogTitle>
          <DialogContent>
            <TextField
              label='Channel Name'
              name='name'
              type='text'
              autoFocus
              required
              inputProps={{
                minLength: 2,
                maxLength: 64,
              }}
            />
            <TextField
              helperText={
                <>
                  <div>
                    Read or watch how at{' '}
                    <Link
                      href='https://learn.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Create Incoming Webhooks
                    </Link>
                    .
                  </div>
                  <div>
                    Submit a request on{' '}
                    <Link
                      href='https://ask-it.kry.se/hc/en-us/requests/new'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Ask-IT
                    </Link>{' '}
                    to get permission to create an Incoming Webhook.
                  </div>
                  <div>
                    Use the{' '}
                    <Link href='/image/heart.png' target='_blank' download>
                      heart
                    </Link>{' '}
                    image when creating the Incoming Webhook.
                  </div>
                </>
              }
              label='Incoming Webhook URL'
              name='teamsWebhookUrl'
              type='url'
              required
              inputProps={{
                maxLength: 1024,
                pattern: teamsWebhookPattern,
              }}
            />
          </DialogContent>
          <DialogActions
            css={css`
              padding-left: 24px;
              padding-right: 24px;
              padding-bottom: 24px;
            `}
          >
            <Button onClick={onClose} type='button' variant='outlined'>
              Cancel
            </Button>
            {destination.id && (
              <Button
                onClick={() => setFieldValue('id', null)}
                type='button'
                variant='outlined'
              >
                Duplicate
              </Button>
            )}
            {saved && <DestinationDelete onClose={onClose} saved={saved} />}
            <Button type='submit' disabled={saving} variant='contained'>
              Save
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default memo(DestinationForm);
