import { createTheme } from '@mui/material/styles';
import { hover, primaryBlack, secondaryBlack } from './color';

export const theme = createTheme({
  palette: {
    primary: {
      main: hover,
    },
    secondary: {
      main: secondaryBlack,
    },
    text: {
      primary: primaryBlack,
    },
  },
  transitions: {
    duration: {
      leavingScreen: 0,
    },
  },
  typography: {
    h1: {
      fontSize: '18px',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 1.15,
    },
  },
});
