import { Country, Language } from '../api';

/**
 * @see https://github.com/webbhalsa/krystals/blob/master/language/src/main/java/se/kry/language/model/MarketLocale.java
 */
export const languagesByCountry = (
  country?: Country | null | undefined
): readonly Language[] => {
  switch (country) {
    case Country.Se:
      return [Language.Sv, Language.En];
    case Country.Gb:
      return [Language.En];
    case Country.No:
      return [Language.Nb, Language.En];
    case Country.Fr:
      return [Language.Fr, Language.En];
  }

  return Object.values(Language);
};
