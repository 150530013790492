import { memo } from 'react';
import { useDeleteDestination } from '../../api';
import { Button } from '../../material';
import type { MouseEventHandler } from 'react';
import type { Destination } from '../../api';

interface Props {
  readonly onClose: () => void;
  readonly saved: Destination;
}

const DestinationDelete = ({ onClose, saved }: Props) => {
  const [deleteDestination, deleting] = useDeleteDestination();

  const onDelete: MouseEventHandler<HTMLButtonElement> = () => {
    if (
      window.confirm(
        `Are you sure you want to delete the channel “${saved.name}”?`
      )
    ) {
      deleteDestination(saved.id).then(onClose);
    }
  };

  return (
    <Button
      onClick={onDelete}
      type='button'
      disabled={deleting}
      variant='outlined'
    >
      Delete
    </Button>
  );
};

export default memo(DestinationDelete);
