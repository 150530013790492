import { memo } from 'react';
import { Country } from '../../api';

interface Props {
  readonly country: Country | null | undefined;
}

const TimeZone = ({ country }: Props) => (
  <>
    {country && (
      <span
        title={
          country === Country.Gb
            ? 'Greenwich Mean Time (GMT)'
            : 'Central European Time (CET) or Central European Summer Time (CEST)'
        }
      >
        {country === Country.Gb ? 'GMT' : 'CET/CEST'}
      </span>
    )}
  </>
);

export default memo(TimeZone);
