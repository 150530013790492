import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getApiKey } from '../helper';

const link = createHttpLink({
  uri: '/api/no/signicat/graphql',
});

const authLink = setContext((_, context) => {
  const apiKey = getApiKey();

  return apiKey
    ? {
        ...context,
        headers: {
          ...context.headers,
          'x-api-key': apiKey,
        },
      }
    : context;
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
});
