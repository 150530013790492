import { memo } from 'react';

interface Props {
  readonly children: React.ReactNode | React.ReactNode[];
  readonly min?: React.ReactNode | React.ReactNode[];
  readonly max?: React.ReactNode | React.ReactNode[];
  readonly minFirst?: boolean;
}

const hasValue = (value: React.ReactNode | React.ReactNode[]): boolean =>
  Boolean(value) || value === 0;

const Range = ({ children, min, max, minFirst }: Props) =>
  minFirst ? (
    <>
      {hasValue(min) && <>{min} ≤ </>}
      {children}
      {hasValue(max) && <> ≤ {max}</>}
    </>
  ) : (
    <>
      {hasValue(max) && <>{max} ≥ </>}
      {children}
      {hasValue(min) && <> ≥ {min}</>}
    </>
  );

export default memo(Range);
