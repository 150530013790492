/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import MuiAlarmIcon from '@mui/icons-material/Alarm';
import { criticalText } from '../../style';
import type { SvgIconProps } from '@mui/material';

export const AlarmIcon = (props: SvgIconProps) => (
  <MuiAlarmIcon
    css={css`
      color: ${criticalText};
    `}
    {...props}
  />
);
