import { memo } from 'react';
import { useDeleteAlert } from '../../api';
import { Button } from '../../material';
import type { MouseEventHandler } from 'react';
import type { Alert } from '../../api';

interface Props {
  readonly onClose: () => void;
  readonly saved: Alert;
}

const DestinationDelete = ({ onClose, saved }: Props) => {
  const [deleteAlert, deleting] = useDeleteAlert();

  const onDelete: MouseEventHandler<HTMLButtonElement> = () => {
    if (window.confirm(`Are you sure you want to delete this alert?`)) {
      deleteAlert(saved.id).then(onClose);
    }
  };

  return (
    <Button
      onClick={onDelete}
      type='button'
      disabled={deleting}
      variant='outlined'
    >
      Delete
    </Button>
  );
};

export default memo(DestinationDelete);
