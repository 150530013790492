import { memo } from 'react';
import DestinationForm from './DestinationForm';
import { Dialog } from '../../material';
import type { Destination } from '../../api';

interface Props {
  readonly close: () => void;
  readonly open: boolean;
  readonly saved: Destination | undefined;
}

const DestinationDialog = ({ close, open, saved }: Props) => (
  <Dialog open={open} onClose={close}>
    {open && <DestinationForm onClose={close} saved={saved} />}
  </Dialog>
);

export default memo(DestinationDialog);
