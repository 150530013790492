// TODO: Unit test.
export const prettyPlural = (
  name: string,
  count: readonly unknown[] | number | null | undefined
) => {
  if (count && Array.isArray(count)) {
    count = count.length;
  }

  return count === 1 ? name : `${name}s`;
};
