/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { memo } from 'react';
import {
  countryLabel,
  interventionLabel,
  languageLabel,
  professionLabel,
} from './label';
import Minutes from './Minutes';
import Range from './Range';
import { prettyTime } from '../helper';
import {
  AddIcon,
  AlarmIcon,
  CheckIcon,
  EditIcon,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../material';
import { criticalBackground, spacing } from '../style';
import type { Alert, Destination } from '../api';

interface Props {
  readonly alerts: readonly Alert[] | undefined;
  readonly destinations: readonly Destination[] | undefined;
  readonly editAlert: (alert?: Alert) => void;
}

const Alerts = ({ alerts, destinations, editAlert }: Props) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Microsoft Teams Channel</TableCell>
          <TableCell align='center'>Country</TableCell>
          <TableCell align='center'>Language</TableCell>
          <TableCell align='center'>Profession</TableCell>
          <TableCell align='center'>Intervention</TableCell>
          <TableCell align='center'>Start When</TableCell>
          <TableCell align='center'>Reminder</TableCell>
          <TableCell align='center'>End When</TableCell>
          <TableCell align='center'>Throttle</TableCell>
          <TableCell icon>
            <IconButton title='Add Alert' onClick={() => editAlert()}>
              <AddIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {alerts?.map((alert) => (
          <TableRow
            key={alert.id}
            css={
              alert.active &&
              css`
                background-color: ${criticalBackground};
              `
            }
          >
            <TableCell align='center' icon>
              {alert.active ? (
                <AlarmIcon titleAccess='The alert is currently active.' />
              ) : (
                <CheckIcon titleAccess='Everything is fine.' />
              )}
            </TableCell>
            <TableCell>
              {
                destinations?.find(
                  (destination) => destination.id === alert.destinationId
                )?.name
              }
              {!destinations && <CircularProgress />}
            </TableCell>
            <TableCell align='center'>{countryLabel[alert.country]}</TableCell>
            <TableCell align='center'>
              {languageLabel[alert.language]}
            </TableCell>
            <TableCell align='center'>
              {professionLabel[alert.profession]}
            </TableCell>
            <TableCell align='center'>
              {interventionLabel[alert.intervention]}
            </TableCell>
            <TableCell align='center'>
              <div>
                <Range
                  min={
                    typeof alert.start.minWaitingTimeMinutes === 'number' && (
                      <Minutes minutes={alert.start.minWaitingTimeMinutes} />
                    )
                  }
                  max={
                    typeof alert.start.maxWaitingTimeMinutes === 'number' && (
                      <Minutes minutes={alert.start.maxWaitingTimeMinutes} />
                    )
                  }
                >
                  waiting time
                </Range>
              </div>
              {(alert.start.minTime || alert.start.maxTime) && (
                <div>
                  <Range
                    min={prettyTime(alert.start.minTime)}
                    max={prettyTime(alert.start.maxTime)}
                    minFirst
                  >
                    time
                  </Range>
                </div>
              )}
            </TableCell>
            <TableCell align='center'>
              {Boolean(alert.reminder.messageTemplate) && (
                <Minutes minutes={alert.reminder.throttleMinutes} />
              )}
            </TableCell>
            <TableCell align='center'>
              <div>
                <Range
                  min={
                    typeof alert.end.minWaitingTimeMinutes === 'number' && (
                      <Minutes minutes={alert.end.minWaitingTimeMinutes} />
                    )
                  }
                  max={
                    typeof alert.end.maxWaitingTimeMinutes === 'number' && (
                      <Minutes minutes={alert.end.maxWaitingTimeMinutes} />
                    )
                  }
                  minFirst
                >
                  waiting time
                </Range>
              </div>
              {(alert.end.minTime || alert.end.maxTime) && (
                <div>
                  <Range
                    min={prettyTime(alert.end.minTime)}
                    max={prettyTime(alert.end.maxTime)}
                    minFirst
                  >
                    time
                  </Range>
                </div>
              )}
            </TableCell>
            <TableCell align='center'>
              <Minutes minutes={alert.throttleMinutes} />
            </TableCell>
            <TableCell icon>
              <IconButton title='Edit Alert' onClick={() => editAlert(alert)}>
                <EditIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    {!alerts && (
      <div
        css={css`
          padding-top: ${spacing};
          text-align: center;
        `}
      >
        <CircularProgress />
      </div>
    )}
  </TableContainer>
);

export default memo(Alerts);
