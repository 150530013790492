import { TextField as MuiTextField } from '@mui/material';
import { useField } from 'formik';
import { useCallback, useEffect, useRef } from 'react';
import { toNumber } from '../helper';
import type { TextFieldProps as MuiTextFieldProps } from '@mui/material';
import type { ChangeEvent } from 'react';

export type TextFieldProps = MuiTextFieldProps & {
  /**
   * Name attribute of the `input` element.
   */
  readonly name: string;
};

export const TextField = (props: TextFieldProps) => {
  const { autoFocus, name, select, type } = props;

  // Automatically use Formik.
  const [{ onChange, value }, , { setValue }] = useField(name);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (type === 'number') {
        setValue(toNumber(event.target.value));
      } else {
        onChange(event);
      }
    },
    [type, setValue, onChange]
  );

  // Autofocus the TextField after it's added to the DOM.
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!autoFocus) {
      return;
    }

    const timeout = setTimeout(() => {
      const inputElement = inputRef.current;
      if (inputElement) {
        inputElement.focus();
      }
    }, 1);

    return () => clearTimeout(timeout);
  }, [autoFocus]);

  return (
    <MuiTextField
      id={name.replaceAll('.', '-')}
      inputRef={inputRef}
      margin='dense'
      onChange={handleChange}
      value={select && value === undefined ? '' : value}
      variant='outlined'
      fullWidth
      {...props}
    />
  );
};
