/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import MuiCheckIcon from '@mui/icons-material/Check';
import { hintText } from '../../style';
import type { SvgIconProps } from '@mui/material';

export const CheckIcon = (props: SvgIconProps) => (
  <MuiCheckIcon
    css={css`
      color: ${hintText};
    `}
    {...props}
  />
);
