/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import MuiAddIcon from '@mui/icons-material/Add';
import { action } from '../../style';
import type { SvgIconProps } from '@mui/material';

export const AddIcon = (props: SvgIconProps) => (
  <MuiAddIcon
    css={css`
      color: ${action};
    `}
    {...props}
  />
);
