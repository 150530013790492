/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import MuiEditIcon from '@mui/icons-material/Edit';
import { action } from '../../style';
import type { SvgIconProps } from '@mui/material';

export const EditIcon = (props: SvgIconProps) => (
  <MuiEditIcon
    css={css`
      color: ${action};
    `}
    {...props}
  />
);
