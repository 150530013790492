import { Tabs as MuiTabs } from '@mui/material';
import type { TabsProps as MuiTabsProps } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';

export interface TabsProps extends MuiTabsProps {
  readonly setValue?:
    | Dispatch<SetStateAction<number>>
    | Dispatch<SetStateAction<string>>;
}

export const Tabs = (props: TabsProps) => {
  return (
    <MuiTabs
      onChange={(_, newValue) => {
        props.setValue?.(newValue);
      }}
      {...props}
      TabIndicatorProps={{
        sx: {
          height: '4px',
        },
      }}
    />
  );
};
