/**
 * Convert a `value` in a `TextField` into a `number` or `null`.
 */
export const toNumber = (
  value: number | string | null | undefined
): number | null => {
  if (typeof value === 'string') {
    value = value.trim();
    if (value) {
      value = Number(value);
    }
  }
  if (typeof value !== 'number' || Number.isNaN(value)) {
    value = null;
  }

  return value;
};
