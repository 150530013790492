/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Formik, Form } from 'formik';
import { memo } from 'react';
import { Button, KeyIcon, TextField } from '../../material';
import { spacingLg } from '../../style';
import type { Dispatch, SetStateAction } from 'react';

interface Props {
  readonly apiKey: string | undefined;
  readonly setApiKey: Dispatch<SetStateAction<string | undefined>>;
  readonly setLoginError: Dispatch<SetStateAction<boolean>>;
}

interface FormValue {
  readonly apiKey: string | undefined;
}

const ApiKeyForm = ({ apiKey, setApiKey, setLoginError }: Props) => {
  const onSubmit = ({ apiKey }: FormValue) => {
    setApiKey(apiKey);
    setLoginError(false);
  };

  return (
    <div
      css={css`
        margin-top: auto;
        margin-bottom: auto;
      `}
    >
      <Formik initialValues={{ apiKey }} onSubmit={onSubmit}>
        <Form>
          <TextField
            label='Password'
            name='apiKey'
            type='password'
            autoFocus
            required
            inputProps={{
              minLength: 36,
              maxLength: 36,
            }}
          />
          <Button
            startIcon={<KeyIcon />}
            type='submit'
            variant='contained'
            css={css`
              margin-top: ${spacingLg};
            `}
          >
            Login
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default memo(ApiKeyForm);
