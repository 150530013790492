import { gql, useQuery } from '@apollo/client';
import type { ApolloError } from '@apollo/client';
import type { Alert, Query } from '..';

export const GET_ALERTS = gql`
  query getAlerts {
    getAlerts {
      id
      destinationId

      country
      language
      profession
      intervention

      start {
        messageTemplate
        minTime
        maxTime
        lastDateTime

        minWaitingTimeMinutes
        maxWaitingTimeMinutes
      }
      reminder {
        messageTemplate
        lastDateTime

        throttleMinutes
      }
      end {
        messageTemplate
        minTime
        maxTime
        lastDateTime

        minWaitingTimeMinutes
        maxWaitingTimeMinutes
      }

      throttleMinutes
      active
    }
  }
`;

export const useAlerts = (): readonly [
  readonly Alert[] | undefined,
  boolean,
  ApolloError | undefined
] => {
  const { loading, error, data } = useQuery<Query>(GET_ALERTS);

  return [data?.getAlerts, loading, error];
};
