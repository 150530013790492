export interface TabPanelProps {
  readonly children: React.ReactNode | React.ReactNode[];
  readonly index: number | string;
  readonly value: number | string;
}

export const TabPanel = ({ children, index, value }: TabPanelProps) => (
  <div
    role='tabpanel'
    hidden={index !== value}
    aria-labelledby={`tab-${index}`}
    id={`tabpanel-${index}`}
  >
    {index === value && children}
  </div>
);
