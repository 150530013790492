/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import MuiPeopleIcon from '@mui/icons-material/People';
import { msTeams } from '../../style';
import type { SvgIconProps } from '@mui/material';

export const PeopleIcon = (props: SvgIconProps) => (
  <MuiPeopleIcon
    css={css`
      color: ${msTeams};
    `}
    {...props}
  />
);
