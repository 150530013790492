import { Country, Intervention, Language, Profession } from '../api';

export const countryLabel: Readonly<Record<Country, string>> = {
  [Country.Fr]: 'France',
  [Country.Gb]: 'Great Britain',
  [Country.No]: 'Norway',
  [Country.Se]: 'Sweden',
};

export const interventionLabel: Readonly<Record<Intervention, string>> = {
  [Intervention.ChatMeeting]: 'Chat',
  [Intervention.VideoMeeting]: 'Video',
};

export const languageLabel: Readonly<Record<Language, string>> = {
  [Language.En]: 'English',
  [Language.Fr]: 'French',
  [Language.Nb]: 'Norwegian',
  [Language.Sv]: 'Swedish',
};

export const professionLabel: Readonly<Record<Profession, string>> = {
  [Profession.Doctor]: 'Doctor',
  [Profession.Nurse]: 'Nurse',
};
