import { gql, useQuery } from '@apollo/client';
import type { ApolloError } from '@apollo/client';
import type { Destination, Query } from '..';

export const GET_DESTINATIONS = gql`
  query getDestinations {
    getDestinations {
      id
      name
    }
  }
`;

export const useDestinations = (): readonly [
  readonly Destination[] | undefined,
  boolean,
  ApolloError | undefined
] => {
  const { loading, error, data } = useQuery<Query>(GET_DESTINATIONS);

  return [data?.getDestinations, loading, error];
};
