import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import type { ApolloError } from '@apollo/client';
import { GET_ALERTS } from './useAlerts';
import type {
  Alert,
  AlertInput,
  AlertPhaseInput,
  Mutation,
  MutationSaveAlertArgs,
  ReminderInput,
} from '..';

const SAVE_ALERT = gql`
  mutation saveAlert($alert: AlertInput!) {
    saveAlert(alert: $alert) {
      id
      destinationId

      country
      language
      profession
      intervention

      start {
        messageTemplate
        minTime
        maxTime
        lastDateTime

        minWaitingTimeMinutes
        maxWaitingTimeMinutes
      }
      reminder {
        messageTemplate
        lastDateTime

        throttleMinutes
      }
      end {
        messageTemplate
        minTime
        maxTime
        lastDateTime

        minWaitingTimeMinutes
        maxWaitingTimeMinutes
      }

      throttleMinutes
      active
    }
  }
`;

export type SaveAlert = (alert: AlertInput) => Promise<Alert | undefined>;

export const useSaveAlert = (): readonly [
  SaveAlert,
  boolean,
  ApolloError | undefined
] => {
  const [mutate, { loading, error }] = useMutation<
    Mutation,
    MutationSaveAlertArgs
  >(SAVE_ALERT, { refetchQueries: [{ query: GET_ALERTS }, 'getAlerts'] });

  const saveAlert = useCallback(
    (alert: AlertInput) =>
      mutate({
        variables: {
          alert: stripExtraFields(alert),
        },
      }).then(({ data }) => data?.saveAlert),
    [mutate]
  );

  return [saveAlert, loading, error];
};

const stripExtraFields = ({
  id,
  destinationId,
  country,
  language,
  profession,
  intervention,
  start,
  reminder,
  end,
  throttleMinutes,
}: AlertInput): AlertInput => ({
  id,
  destinationId,
  country,
  language,
  profession,
  intervention,
  start: stripExtraFieldsPhase(start),
  reminder: stripExtraFieldsReminder(reminder),
  end: stripExtraFieldsPhase(end),
  throttleMinutes,
});

const stripExtraFieldsPhase = ({
  maxTime,
  maxWaitingTimeMinutes,
  messageTemplate,
  minTime,
  minWaitingTimeMinutes,
}: AlertPhaseInput): AlertPhaseInput => ({
  maxTime,
  maxWaitingTimeMinutes,
  messageTemplate,
  minTime,
  minWaitingTimeMinutes,
});

const stripExtraFieldsReminder = ({
  messageTemplate,
  throttleMinutes,
}: ReminderInput): ReminderInput => ({
  messageTemplate,
  throttleMinutes,
});
