import { memo, useCallback, useEffect, useState } from 'react';
import AlertDialog from './edit/AlertDialog';
import DestinationDialog from './edit/DestinationDialog';
import Tabs from './Tabs';
import { useAlerts, useDestinations } from '../api';
import { isLoginError } from '../helper';
import type { Dispatch, SetStateAction } from 'react';
import type { Alert, Destination } from '../api';

interface Props {
  readonly setLoginError: Dispatch<SetStateAction<boolean>>;
}

enum Entity {
  Alert,
  Destination,
}

interface Dialog {
  readonly entity: Entity;
  readonly id: string | undefined;
}

const LoggedIn = ({ setLoginError }: Props) => {
  const [alerts, , alertsError] = useAlerts();
  const [destinations, , destinationsError] = useDestinations();
  const [dialog, setDialog] = useState<Dialog | undefined>();

  useEffect(() => {
    if (isLoginError(alertsError) || isLoginError(destinationsError)) {
      setLoginError(true);
    }
  }, [alertsError, destinationsError, setLoginError]);

  const editAlert = useCallback(
    (alert?: Alert) => {
      setDialog({
        entity: Entity.Alert,
        id: alert?.id,
      });
    },
    [setDialog]
  );

  const editDestination = useCallback(
    (destination?: Destination) => {
      setDialog({
        entity: Entity.Destination,
        id: destination?.id,
      });
    },
    [setDialog]
  );

  const closeDialog = useCallback(() => {
    setDialog(undefined);
  }, [setDialog]);

  return (
    <>
      <Tabs
        alerts={alerts}
        destinations={destinations}
        editAlert={editAlert}
        editDestination={editDestination}
      />
      <AlertDialog
        close={closeDialog}
        destinations={destinations}
        open={dialog?.entity === Entity.Alert}
        saved={dialog && alerts?.find(({ id }) => id === dialog.id)}
      />
      <DestinationDialog
        close={closeDialog}
        open={dialog?.entity === Entity.Destination}
        saved={dialog && destinations?.find(({ id }) => id === dialog.id)}
      />
    </>
  );
};

export default memo(LoggedIn);
