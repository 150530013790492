/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Formik, Form } from 'formik';
import { sortBy } from 'lodash';
import { memo } from 'react';
import AlertDelete from './AlertDelete';
import MessageHelperText from './MessageHelperText';
import TimeZone from './TimeZone';
import {
  countryLabel,
  interventionLabel,
  languageLabel,
  professionLabel,
} from '../label';
import { Country, Intervention, Profession, useSaveAlert } from '../../api';
import { languagesByCountry, prettyCount, prettyPlural } from '../../helper';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '../../material';
import { spacing, spacingLg } from '../../style';
import type { DeepPartial } from 'ts-essentials';
import type { Alert, AlertInput, Destination } from '../../api';

interface Props {
  readonly destinations: readonly Destination[] | undefined;
  readonly onClose: () => void;
  readonly saved: Alert | undefined;
}

const AlertForm = ({ destinations, onClose, saved }: Props) => {
  const [saveAlert, saving] = useSaveAlert();

  const onSubmit = (alert: DeepPartial<AlertInput>) => {
    saveAlert(alert as AlertInput).then(onClose);
  };

  return (
    <Formik initialValues={saved || {}} onSubmit={onSubmit}>
      {({ setFieldValue, values: alert }) => {
        const selectedProfessionLabel = alert.profession
          ? professionLabel[alert.profession].toLowerCase()
          : 'clinician';

        return (
          <Form>
            <DialogTitle>{alert.id ? 'Edit' : 'Add'} Alert</DialogTitle>
            <DialogContent>
              <TextField
                label='Microsoft Teams Channel'
                name='destinationId'
                required
                select
                SelectProps={{
                  defaultOpen: !saved,
                }}
              >
                {sortBy(destinations, ({ name }) => name.toLowerCase()).map(
                  ({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  )
                )}
              </TextField>
              <TextField label='Country' name='country' required select>
                {Object.values(Country).map((country) => (
                  <MenuItem key={country} value={country}>
                    {countryLabel[country]}
                  </MenuItem>
                ))}
              </TextField>
              <TextField label='Language' name='language' required select>
                {languagesByCountry(alert.country).map((language) => (
                  <MenuItem key={language} value={language}>
                    {languageLabel[language]}
                  </MenuItem>
                ))}
              </TextField>
              <TextField label='Profession' name='profession' required select>
                {Object.values(Profession).map((profession) => (
                  <MenuItem key={profession} value={profession}>
                    {professionLabel[profession]}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label='Intervention'
                name='intervention'
                required
                select
              >
                {sortBy(
                  Object.values(Intervention),
                  (intervention) => intervention !== Intervention.VideoMeeting
                ).map((intervention) => (
                  <MenuItem key={intervention} value={intervention}>
                    {interventionLabel[intervention]}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                helperText={`After the alert ends, it cannot start again for at least ${
                  alert.throttleMinutes
                    ? prettyCount('minute', alert.throttleMinutes)
                    : '# minutes'
                }.`}
                label='Throttle'
                name='throttleMinutes'
                type='number'
                required
                inputProps={{
                  min: 1,
                  step: 1,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {prettyPlural('minute', alert.throttleMinutes)}
                    </InputAdornment>
                  ),
                }}
              />

              <Typography
                variant='h6'
                css={css`
                  margin-top: ${spacingLg};
                `}
              >
                Alert Starts
              </Typography>
              <TextField
                helperText={<MessageHelperText alert={alert} />}
                label='Message'
                minRows={3}
                name='start.messageTemplate'
                multiline
                inputProps={{
                  maxLength: 1024,
                }}
              />
              <Grid
                container
                spacing={2}
                css={css`
                  margin-top: ${spacing};
                `}
              >
                <Grid item xs={6}>
                  <TextField
                    helperText={`Not enough ${selectedProfessionLabel}s.`}
                    label='Min Waiting Time'
                    name='start.minWaitingTimeMinutes'
                    type='number'
                    required={
                      typeof alert.start?.maxWaitingTimeMinutes !== 'number'
                    }
                    inputProps={{
                      min: 0,
                      step: 1,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {prettyPlural(
                            'minute',
                            alert.start?.minWaitingTimeMinutes
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    helperText={`Overstaffed: too many ${selectedProfessionLabel}s.`}
                    label='Max Waiting Time'
                    name='start.maxWaitingTimeMinutes'
                    type='number'
                    inputProps={{
                      min: alert.start?.minWaitingTimeMinutes || 0,
                      step: 1,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {prettyPlural(
                            'minute',
                            alert.start?.maxWaitingTimeMinutes
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                css={css`
                  margin-top: ${spacing};
                `}
              >
                <Grid item xs={6}>
                  <TextField
                    label='Min Time'
                    name='start.minTime'
                    type='time'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <TimeZone country={alert.country} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label='Max Time'
                    name='start.maxTime'
                    type='time'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: alert.start?.minTime,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <TimeZone country={alert.country} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Typography
                variant='h6'
                css={css`
                  margin-top: ${spacingLg};
                `}
              >
                Reminder
              </Typography>
              <TextField
                helperText={<MessageHelperText alert={alert} />}
                label='Message'
                minRows={3}
                name='reminder.messageTemplate'
                multiline
                inputProps={{
                  maxLength: 1024,
                }}
              />
              <Grid
                container
                spacing={2}
                css={css`
                  margin-top: ${spacing};
                `}
              >
                <Grid item xs={12}>
                  <TextField
                    label='Send Every'
                    name='reminder.throttleMinutes'
                    type='number'
                    required
                    inputProps={{
                      min: 5,
                      step: 1,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {prettyPlural(
                            'minute',
                            alert.reminder?.throttleMinutes
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Typography
                variant='h6'
                css={css`
                  margin-top: ${spacingLg};
                `}
              >
                Alert Ends
              </Typography>
              <TextField
                helperText={<MessageHelperText alert={alert} />}
                label='Message'
                minRows={3}
                name='end.messageTemplate'
                multiline
                inputProps={{
                  maxLength: 1024,
                }}
              />
              <Grid
                container
                spacing={2}
                css={css`
                  margin-top: ${spacing};
                `}
              >
                <Grid item xs={6}>
                  <TextField
                    helperText={`Enough ${selectedProfessionLabel}s.`}
                    label='Max Waiting Time'
                    name='end.maxWaitingTimeMinutes'
                    type='number'
                    required={
                      typeof alert.start?.minWaitingTimeMinutes === 'number'
                    }
                    inputProps={{
                      max:
                        typeof alert.start?.minWaitingTimeMinutes === 'number'
                          ? alert.start?.minWaitingTimeMinutes - 1
                          : null,
                      min: 0,
                      step: 1,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {prettyPlural(
                            'minute',
                            alert.end?.maxWaitingTimeMinutes
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    helperText={`Ideally staffed: not too many ${selectedProfessionLabel}s.`}
                    label='Min Waiting Time'
                    name='end.minWaitingTimeMinutes'
                    type='number'
                    required={
                      typeof alert.start?.maxWaitingTimeMinutes === 'number'
                    }
                    inputProps={{
                      min:
                        typeof alert.start?.maxWaitingTimeMinutes === 'number'
                          ? alert.start?.maxWaitingTimeMinutes + 1
                          : 0,
                      step: 1,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {prettyPlural(
                            'minute',
                            alert.end?.minWaitingTimeMinutes
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                css={css`
                  margin-top: ${spacing};
                `}
              >
                <Grid item xs={6}>
                  <TextField
                    label='Min Time'
                    name='end.minTime'
                    type='time'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <TimeZone country={alert.country} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label='Max Time'
                    name='end.maxTime'
                    type='time'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: alert.end?.minTime,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <TimeZone country={alert.country} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              css={css`
                padding-left: 24px;
                padding-right: 24px;
                padding-bottom: 24px;
              `}
            >
              <Button onClick={onClose} type='button' variant='outlined'>
                Cancel
              </Button>
              {alert.id && (
                <Button
                  onClick={() => setFieldValue('id', null)}
                  type='button'
                  variant='outlined'
                >
                  Duplicate
                </Button>
              )}
              {saved && <AlertDelete onClose={onClose} saved={saved} />}
              <Button type='submit' disabled={saving} variant='contained'>
                Save
              </Button>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default memo(AlertForm);
