/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { memo, useState } from 'react';
import ApiKeyForm from './edit/ApiKeyForm';
import LoggedIn from './LoggedIn';
import Top from './Top';
import { useApiKey } from '../helper';
import { Container } from '../material';

const Layout = () => {
  const [apiKey, setApiKey] = useApiKey();
  const [loginError, setLoginError] = useState(false);

  return (
    <>
      <Top />
      <Container
        maxWidth='xl'
        css={css`
          flex-grow: 1;

          display: flex;
          flex-direction: column;
        `}
      >
        {apiKey && !loginError ? (
          <LoggedIn setLoginError={setLoginError} />
        ) : (
          <ApiKeyForm
            apiKey={apiKey}
            setApiKey={setApiKey}
            setLoginError={setLoginError}
          />
        )}
      </Container>
    </>
  );
};

export default memo(Layout);
