/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { sortBy } from 'lodash';
import { memo } from 'react';
import {
  AddIcon,
  CircularProgress,
  EditIcon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../material';
import { spacing } from '../style';
import type { Alert, Destination } from '../api';

interface Props {
  readonly alerts: readonly Alert[] | undefined;
  readonly destinations: readonly Destination[] | undefined;
  readonly editDestination: (destination?: Destination) => void;
}

const Destinations = ({ alerts, destinations, editDestination }: Props) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Microsoft Teams Channel</TableCell>
          <TableCell align='center'>Alerts</TableCell>
          <TableCell icon>
            <IconButton
              title='Add Teams Channel'
              onClick={() => editDestination()}
            >
              <AddIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortBy(destinations, ({ name }) => name.toLowerCase()).map(
          (destination) => (
            <TableRow key={destination.id}>
              <TableCell>{destination.name}</TableCell>
              <TableCell align='center'>
                {alerts
                  ?.filter((alert) => alert.destinationId === destination.id)
                  ?.length?.toLocaleString()}
                {!alerts && <CircularProgress />}
              </TableCell>
              <TableCell icon>
                <IconButton
                  title='Edit Teams Channel'
                  onClick={() => editDestination(destination)}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
    {!destinations && (
      <div
        css={css`
          padding-top: ${spacing};
          text-align: center;
        `}
      >
        <CircularProgress />
      </div>
    )}
  </TableContainer>
);

export default memo(Destinations);
